<template>
  <BaseLayout>
    <SubHeader></SubHeader>
    <div class="container" style="padding-bottom: 24px;">
      <h1 class="text-center">Advertise with MSM</h1>
      <div class="card-deck mb-3 text-center">
        <div class="card mb-4 box-shadow">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">{{plans.regional.name}}</h4>
          </div>
          <div class="card-body">
            <div>
              <h1 class="card-title pricing-card-title">${{plans.regional.prices.monthly.price}}</h1>
              <h1 class="card-title pricing-card-title">PER MONTH</h1>
            </div>
            <p>
              The Premium Plan includes the benefits of the Basic Listing (map pin/list on both website and app) plus a scrolling ad on the website which gives upgraded visibility. The advertisement rotates on all search options on desktop and pops up on mobile web. <i>(Please visit www.manestreetmarket.com and click on Horses for Sale or Service Providers to see website ads.)</i> Additional perk includes being featured at least once in the bi-monthly Mane Street Market email newsletter that goes to over 5,000 equestrians. 
            </p>
          </div>
          <div class="card-footer">
            <router-link v-if="$route.query.next_id && $route.query.next_page" :to="{name: 'subscribe', params: {id: plans.regional.code}, query: {next_page: $route.query.next_page, next_id: $route.query.next_id}}" type="button" class="btn btn-lg btn-block btn-primary">Get Started</router-link>
            <router-link v-if="$route.query.next_id == null || $route.query.next_page == null" to="/save-ads" type="button" class="btn btn-lg btn-block btn-primary">Get Started</router-link>
          </div>
        </div>
        <!-- <div class="card mb-4 box-shadow">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">{{plans.national.name}}</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">${{plans.national.prices.monthly.price}} <small class="text-muted">/ mo</small></h1>
            <p>
              App and Website Advertiser includes benefits of Website Advertiser and Basic Listing plus rotating banner advertisement on app. Ads on web and mobile apps include a hyperlink to advertiser's website. This option provides maximum exposure to the MSM audience. Please contact support@manestreetmarket.com for ad specs before choosing this option. <i>(Please download the Mane Street Market app to see current app banner ads scrolling on the bottom of the screen.)</i>
            </p>
          </div>
          <div class="card-footer">
            <router-link v-if="$route.query.next_id && $route.query.next_page" :to="{name: 'subscribe', params: {id: plans.national.code}, query: {next_page: $route.query.next_page, next_id: $route.query.next_id}}" type="button" class="btn btn-lg btn-block btn-primary">Get Started</router-link>
            <router-link v-if="$route.query.next_id == null || $route.query.next_page == null" to="/save-ads" type="button" class="btn btn-lg btn-block btn-primary">Get Started</router-link>
          </div>
        </div> -->
        <div class="card mb-4 box-shadow">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">{{plans.premier.name}}</h4>
          </div>
          <div class="card-body">
            <div>
              <h1 class="card-title pricing-card-title">${{plans.premier.prices.monthly.price}}</h1>
              <h1 class="card-title pricing-card-title">PER MONTH</h1>
            </div>
            <p>
              COMING SOON!
            </p>
          </div>
          <!-- <div class="card-footer">
            <router-link v-if="$route.query.next_id && $route.query.next_page" :to="{name: 'subscribe', params: {id: plans.premier.code}, query: {next_page: $route.query.next_page, next_id: $route.query.next_id}}" type="button" class="btn btn-lg btn-block btn-primary">Get Started</router-link>
            <router-link v-if="$route.query.next_id == null || $route.query.next_page == null" to="/save-ads" type="button" class="btn btn-lg btn-block btn-primary">Get Started</router-link>
          </div> -->
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/Base.vue';
import SubHeader from '@/components/SubHeader.vue';
import plans from '@/constants/plans';

export default {
  components: {
    BaseLayout,
    SubHeader
  },
  data() {
    return {
      plans: plans
    }
  },
  async mounted() {
    document.title = 'Subscribe - Mane Street Market';
    window.scrollTo(0, 0);
  }
}
</script>